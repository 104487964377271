<template>
<div class="bg">
   <img src="../assets/fafangji/1.png" alt="">
   <img src="../assets/fafangji/2.png" alt="">
   <img src="../assets/fafangji/3.png" alt="">
   <img src="../assets/fafangji/4.png" alt="">
   <img src="../assets/fafangji/5.png" alt="">
   <img src="../assets/fafangji/6.png" alt="">
   <img src="../assets/fafangji/7.png" alt="">
</div>
</template>
<script>
export default {
  data(){
    return{
    }
  }
}
</script>
<style scoped>
.bg{
  /*height:calc(100vh);*/
  margin: 0 auto;
  display: flex;
  justify-items: center;
  flex-direction: column;
  width: 80vw;
}
img{
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-top:4px;;
}

</style>
